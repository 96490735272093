.Question.current {
	.Wage_lowerHand {
		animation: WageLHand 5s ease-in-out 3s infinite;
		transform-origin: 0 100%;
	}

	.Wage_upperHand {
		animation: WageUHand 5s ease-in-out 3s infinite;
		transform-origin: 100% 0;
	}

	.Wage_banknote {
		animation: WageNote 5s ease-in-out 3s infinite;
		transform-origin: 342.39px 240.67px;
	}
}

@keyframes WageLHand {
	0% {
		transform: rotate(0);
	}
	20% {
		transform: rotate(-2deg);
	}
	21% {
		transform: rotate(-2deg);
	}
	45% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(0);
	}
}

@keyframes WageUHand {
	0% {
		transform: rotate(0);
	}
	20% {
		transform: rotate(-2deg);
	}
	21% {
		transform: rotate(-2deg);
	}
	45% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(0);
	}
}

@keyframes WageNote {
	0% {
		transform: translateX(0);
	}
	20% {
		transform: translateX(5px);
	}
	21% {
		transform: translateX(5px);
	}
	45% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(0);
	}
}
