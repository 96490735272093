.Footer {
	height: 120px;
	width: 100%;
	margin-top: 4em;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.FooterTitle {
		font-size: 16px;
		color: #fff;
		font-weight: 300;
		padding-top: 1.5em;
		padding-bottom: 0.3em;
	}

	.Socials {
		margin-top: 0.6em;
		margin-bottom: 1em;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			cursor: pointer;
		}

		i {
			margin: 0 14px;
			font-size: 26px;
			color: #fff;
		}
	}
}

@media screen and (min-width: 480px) {
	.Footer {
		height: 133px;
	}
}
