.Modal {
	height: 100%;
	top: 0;
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	position: fixed;
	align-items: center;
	z-index: 100;
	-webkit-animation: fadeInOut 2s ease forwards;
	animation: fadeInOut 2s ease forwards;
	opacity: 0;

	@keyframes fadeInOut {
		33% {
			opacity: 1;
		}
		66% {
			opacity: 1;
		}
	}

	.ModalContent {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 200px;
		width: 200px;
		background-color: #fff;
		border-radius: 10px;

		svg {
			width: 100px;
			display: block;
		}

		.path {
			stroke-dasharray: 1000;
			stroke-dashoffset: 0;
			&.circle {
				-webkit-animation: dash 0.9s ease-in-out;
				animation: dash 0.9s ease-in-out;
			}
			&.line {
				stroke-dashoffset: 1000;
				-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
				animation: dash 0.9s 0.35s ease-in-out forwards;
			}
			&.check {
				stroke-dashoffset: -100;
				-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
				animation: dash-check 0.9s 0.35s ease-in-out forwards;
			}
		}

		@-webkit-keyframes dash {
			0% {
				stroke-dashoffset: 1000;
			}
			100% {
				stroke-dashoffset: 0;
			}
		}

		@keyframes dash {
			0% {
				stroke-dashoffset: 1000;
			}
			100% {
				stroke-dashoffset: 0;
			}
		}

		@-webkit-keyframes dash-check {
			0% {
				stroke-dashoffset: -100;
			}
			100% {
				stroke-dashoffset: 900;
			}
		}

		@keyframes dash-check {
			0% {
				stroke-dashoffset: -100;
			}
			100% {
				stroke-dashoffset: 900;
			}
		}
	}
}

@media screen and (min-width: 480px) {
	.Modal {
		height: 600px;
		width: 400px;
		border-radius: 10px;
		box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.1);
		top: unset;
		left: unset;
	}
}
