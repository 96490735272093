.Question.current {
	.Meeting_personBackL {
		animation: MeetingBL 5s ease 3s infinite;
	}

	.Meeting_personBackC {
		animation: MeetingBC 5s ease 3s infinite;
	}

	.Meeting_personBackR {
		animation: MeetingBR 5s ease 3s infinite;
	}

	.Meeting_personFrontL {
		animation: MeetingFL 5s ease 3s infinite;
	}

	.Meeting_personFrontR {
		animation: MeetingFR 5s ease 3s infinite;
	}
}

@keyframes MeetingBL {
	0% {
		transform: translateX(0);
	}
	10% {
		transform: translateX(20px);
	}
	30% {
		transform: translateX(20px);
	}
	40% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes MeetingBC {
	0% {
		transform: translateX(0);
	}
	10% {
		transform: translateX(-5px);
	}
	30% {
		transform: translateX(-5px);
	}
	40% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes MeetingBR {
	0% {
		transform: translateX(0);
	}
	10% {
		transform: translateX(-30px);
	}
	30% {
		transform: translateX(-30px);
	}
	40% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes MeetingFL {
	0% {
		transform: translateX(0);
	}
	10% {
		transform: translateX(10px);
	}
	30% {
		transform: translateX(10px);
	}
	40% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes MeetingFR {
	0% {
		transform: translateX(0);
	}
	10% {
		transform: translateX(-10px);
	}
	30% {
		transform: translateX(-10px);
	}
	40% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(0);
	}
}
