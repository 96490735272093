@import '../../styles/colors';

.liftedUp {
	margin-top: -20px;
}

.Question {
	position: absolute;
	width: 100%;
	height: 100%;
	will-change: transform;
	display: flex;
	align-items: center;
	justify-content: center;

	.QuestionCard {
		position: absolute;
		overflow: hidden;
		display: flex;
		background-color: white;
		will-change: transform;
		height: 100%;
		width: 100%;
		transition: transform 200ms ease-out, box-shadow 200ms ease-out,
			border-radius 200ms ease-out;

		.Top {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: flex-start;

			img,
			svg {
				width: 100%;
				object-fit: contain;
			}
		}

		.Bottom {
			position: absolute;
			bottom: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			._Question {
				padding: 0 1em;
				text-align: center;
				letter-spacing: 1px;
				font-size: 18px;
				line-height: 25px;
				margin-bottom: 2em;
				margin-top: 2em;
				font-size: 20px;
				font-weight: 300;
			}

			.Answers {
				margin-bottom: 3em;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				button {
					background-color: $orange;
					color: #fff;
					text-transform: uppercase;
					font-size: 12px;
					border-radius: 6px;
					margin: 10px;
					text-align: center;
					outline: none;
					border: none;
					width: 140px;
					height: 40px;
					transition: all 140ms ease;
					cursor: pointer;

					&:hover {
						background-color: darken($color: $orange, $amount: 5);
					}

					&:active {
						box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
					}
				}
			}

			.Indicator {
				margin-bottom: 1em;
				margin-top: auto;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}

@media screen and (min-width: 480px) {
	.Question {
		height: 600px;
		width: 400px;

		.QuestionCard {
			border-radius: 10px;

			.QuestionContent .Top img {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
			}

			.Bottom {
				height: 50%;
			}
		}
	}
}

@media screen and (min-height: 630px) {
	.QuestionCard .Bottom {
		height: 55%;
	}
}

@media screen and (max-height: 550px) {
	.Question .QuestionCard .Bottom ._Question {
		margin-bottom: 1em;
	}
	.Question .QuestionCard .Bottom .Answers {
		margin-bottom: 2em;
	}
}

@media screen and (min-height: 630px) and (max-width: 414px) {
	.Question .QuestionCard .Bottom ._Question {
		margin-top: 3em;
	}
}
