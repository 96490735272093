@import '../../styles/colors';

.Dots {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.Dot {
		width: 6px;
		height: 6px;
		background-color: #b8dce8;
		border-radius: 50%;
		margin: 7px;
	}

	.Active {
		width: 20px;
		height: 20px;
		background-color: $blue;
		border-radius: 50%;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
	}
}
