@import '../../styles/colors';

.NotFound {
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.Error {
		color: #fff;
		font-size: 60px;
		margin-bottom: 20px;
	}

	h1 {
		color: $blue;
		width: 80%;
		text-align: center;
	}

	.BackHome {
		display: block;
		margin: 3em auto;
		background-color: $orange;
		width: 140px;
		color: #fff;
		text-decoration: none;
		padding: 20px;
		text-align: center;
		border-radius: 5px;
		font-weight: 700;
	}
}
