.Question.current {
	.Gap_upperStack {
		animation: GapUpperS 6s ease 3s infinite;
	}

	.Gap_leftStack {
		animation: GapLeftS 6s ease 3s infinite;
	}

	.Gap_rightStack {
		animation: GapRightS 6s ease 3s infinite;
	}

	.Gap_arrowL {
		animation: GapArrowL 6s ease 3s infinite;
	}

	.Gap_arrowR {
		animation: GapArrowR 6s ease 3s infinite;
	}
}

@keyframes GapUpperS {
	0%,
	25% {
		opacity: 1;
	}
	30% {
		opacity: 0;
	}
	40% {
		opacity: 0;
	}
	45% {
		opacity: 1;
	}
}

@keyframes GapLeftS {
	0%,
	25% {
		opacity: 1;
	}
	30% {
		opacity: 0;
	}
	45% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@keyframes GapRightS {
	0%,
	25% {
		opacity: 1;
	}
	30% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	55% {
		opacity: 1;
	}
}

@keyframes GapArrowL {
	0%,
	25% {
		opacity: 1;
	}
	30% {
		opacity: 0;
	}
	43% {
		opacity: 0;
	}
	48% {
		opacity: 1;
	}
}

@keyframes GapArrowR {
	0%,
	25% {
		opacity: 1;
	}
	30% {
		opacity: 0;
	}
	48% {
		opacity: 0;
	}
	53% {
		opacity: 1;
	}
}
