.Question.current {
	.ETUI_e,
	.ETUI_u {
		animation: ETUIUpDown 4s ease 2s infinite;
	}

	.ETUI_t,
	.ETUI_i {
		animation: ETUIDownUp 4s ease 2s infinite;
	}
}

@keyframes ETUIUpDown {
	0% {
		transform: translate(0, 0);
	}
	9% {
		transform: translate(1%, 3%);
	}
	24% {
		transform: translate(-1%, -3%);
	}
	33%,
	100% {
		transform: translate(0, 0);
	}
}

@keyframes ETUIDownUp {
	0% {
		transform: translate(0, 0);
	}
	9% {
		transform: translate(-1%, -3%);
	}
	24% {
		transform: translate(1%, 3%);
	}
	33%,
	100% {
		transform: translate(0, 0);
	}
}
