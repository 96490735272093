@import '../../styles/colors';

.Facebook {
	color: rgb(60, 90, 153);
}

.Twitter {
	color: rgb(29, 161, 242);
}

.Whatsapp {
	color: rgb(74, 201, 89);
}

.Check {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #fff;
	height: 100%;
	animation: fade 1s;

	@keyframes fade {
		0%,
		100% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
	}

	.LeaveEmail {
		text-decoration: none;
		color: $orange;
	}

	.Top {
		background: url('/assets/img/home_bg.svg') no-repeat bottom center;
		background-size: cover;
		width: 100%;
		margin-bottom: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		height: 250px;
	}

	h2 {
		margin: 0.6em 0 0;
		animation: fadeIn 700ms ease-in-out;
		animation-delay: 2.5s;
		animation-fill-mode: forwards;
		opacity: 0;
	}

	h1 {
		color: #fff;
		text-transform: uppercase;
		font-size: 30px;
		margin-bottom: -10px;
		margin-top: 4px;
		animation: fadeIn 700ms ease-in-out;
		animation-delay: 2.5s;
		animation-fill-mode: forwards;
		opacity: 0;
	}

	.Result {
		margin-top: 1.5em;
		margin-bottom: 2em;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.Score {
			color: #fff;
			font-weight: 300;
			font-size: 50px;
			animation: fadeIn 700ms ease-in-out;
			animation-delay: 2.7s;
			animation-fill-mode: forwards;
			opacity: 0;
		}

		.Stars {
			margin-top: 1em;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			animation: fadeIn 700ms ease-in-out;
			animation-delay: 2.9s;
			animation-fill-mode: forwards;
			opacity: 0;

			img {
				width: 16px;
				margin-left: 1px;
				margin-right: 1px;
			}
		}
	}

	.Character {
		margin-top: 1em;
		margin-bottom: 1em;
		width: 100%;
		height: 400px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: 100%;
		}
	}

	.Message {
		margin: auto;
		width: 80%;
		font-size: 20px;
		font-weight: 300;
		text-align: center;
		animation: fadeIn 700ms ease-in-out;
		animation-delay: 2.9s;
		animation-fill-mode: forwards;
		opacity: 0;
	}

	.PlayAgain {
		display: block;
		margin: 2em auto;
		background-color: $orange;
		width: 140px;
		color: #fff;
		text-decoration: none;
		padding: 15px;
		text-align: center;
		border-radius: 5px;
		font-weight: 700;
		text-transform: uppercase;
		animation: fadeIn 700ms ease-in-out;
		animation-delay: 2.9s;
		animation-fill-mode: forwards;
		opacity: 0;
	}

	.ShareButtons {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 40px;
		margin-bottom: 32px;
		animation: fadeIn 700ms ease-in-out;
		animation-delay: 2.9s;
		animation-fill-mode: forwards;
		opacity: 0;

		.SocialMediaShareButton {
			outline: none !important;
		}

		i {
			margin: 20px 9px;
			transition: opacity 140ms ease;
			cursor: pointer;

			&:active {
				opacity: 0.9;
			}
		}
	}

	.PageLinks {
		text-align: center;
	}

	.PageLink {
		margin: 0 5px;
		display: inline-block;
		font-size: 12px;
		background-color: $blue;
		width: auto;
		color: #fff;
		text-decoration: none;
		padding: 10px 15px;
		text-align: center;
		border-radius: 5px;
		font-weight: 700;
		text-transform: uppercase;
		animation: fadeIn 700ms ease-in-out;
		animation-delay: 2.9s;
		animation-fill-mode: forwards;
		opacity: 0;
	}
}

.ScrollHint {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 2;
	right: 2em;
	bottom: 1em;
	background: $orange;
	width: 3em;
	height: 3em;
	padding: 1.3em 0.73em;
	border-radius: 50%;
	box-shadow: 0px 0px 8px 0px #3334;
	opacity: 0;
	animation: fadeInDelay 1s linear 5s forwards;

	span {
		color: #fff;
		animation: arrow 5.4s linear 7s infinite;
		font-size: 22px;
		padding-top: 6px;
	}
}

.scrolled .ScrollHint {
	animation: fadeOut 0.3s linear forwards;
	pointer-events: none;
}

@keyframes fadeInDelay {
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes arrow {
	0% {
		transform: translateY(0%);
	}
	5% {
		transform: translateY(15%);
	}
	10% {
		transform: translateY(0%);
	}
	15% {
		transform: translateY(15%);
	}
	20% {
		transform: translateY(-15%);
	}
	25% {
		transform: translateY(15%);
	}
	30% {
		transform: translateY(0);
	}
	35% {
		transform: translateY(15%);
	}
	40% {
		transform: translateY(-15%);
	}
	45% {
		transform: translateY(15%);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0%);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@media screen and (min-width: 480px) {
	.Check {
		position: relative;
		overflow: hidden;
		overflow-y: scroll;
		height: 600px;
		width: 400px;
		border-radius: 10px;
		box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.1);
		margin: auto;
		display: block;

		.Top {
			height: 222px;
		}

		.ScrollHint {
			position: absolute;
			animation: fadeInOutDelay 8s linear 6s forwards;
		}
	}
}

@keyframes fadeInOutDelay {
	5% {
		opacity: 1;
	}

	95% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
