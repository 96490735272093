.Question.current {
	.Cancer_Lungs {
		animation: Inflate 5s ease 3s infinite;
		transform-origin: 415px 160px;
	}
}

@keyframes Inflate {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(1.1);
	}
	21% {
		transform: scale(1.1);
	}
	45% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}
