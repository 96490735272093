@import '../../styles/colors';

body {
	overflow-x: hidden;
}

.Home {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	animation: fade 1s;

	@keyframes fade {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	.Top {
		background: url('/assets/img/home_bg.svg') no-repeat top center;
		background-size: contain;
		width: 100%;
		display: flex;
		align-items: flex-start;
		min-height: 250px;
		padding-top: 1em;
		padding-left: 1em;
		padding-right: 1em;
		margin-bottom: 1em;

		.Character {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			//width: 45%;
			width: auto;
			max-width: 240px;

			img {
				width: 100%;
				//max-width: 100px;
				max-width: none;
			}
		}

		.CTA {
			margin-left: 20px;
			padding-top: 0.5em;
		}

		.HomeLogo {
			width: 40%;
			margin: 0.5em auto;
		}

		// .Character {
		// 	width: auto;
		// 	max-width: 240px;

		// 	img {
		// 		max-width: none;
		// 	}
		// }

		h1 {
			word-spacing: 4px;
			margin: 15px 0;
			font-size: 23px;
			color: #fff;
		}

		.desc {
			text-transform: uppercase;
			color: #000;
			font-weight: 700;
			letter-spacing: 0.5px;
			font-size: 13px;
		}

		.Description {
			width: 73%;
			text-align: center;
			word-spacing: 2px;
			line-height: 18px;
			font-size: 13px;
			font-weight: 300;
		}
	}

	.QuizButton {
		display: block;
		margin: auto;
		background-color: $orange;
		width: 140px;
		color: #fff;
		text-decoration: none;
		padding: 15px;
		text-align: center;
		border-radius: 5px;
		font-weight: 700;
		text-transform: uppercase;
		transition: all 140ms ease;
		cursor: pointer;

		&:hover {
			background-color: darken($color: $orange, $amount: 5);
		}

		&:active {
			box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
		}
	}

	.Bottom {
		margin-top: 4em;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;

		h3 {
			text-transform: uppercase;
			letter-spacing: 2px;
			font-size: 13px;
			font-weight: 300;
			margin: 10px;
		}

		.Tag {
			font-size: 14px;
			color: $orange;
			font-weight: 800;
		}

		.LogoCongress {
			display: block;
			width: 140px;
			margin: 0 auto;

			img {
				width: 100%;
				filter: brightness(0.9) grayscale(100%) contrast(130%);
			}
		}

		h2 {
			letter-spacing: 1px;
			margin-top: 10px;
			margin-bottom: -6px;
		}

		.EventsButton {
			background-color: $blue;
			width: 140px;
			color: #fff;
			text-decoration: none;
			margin-top: 1.5em;
			padding: 15px;
			text-align: center;
			border-radius: 5px;
			font-weight: 700;
			text-transform: uppercase;
			transition: all 140ms ease;
			cursor: pointer;

			&:hover {
				background-color: darken($color: $blue, $amount: 5);
			}

			&:active {
				box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
			}
		}
	}
}

@media screen and (min-width: 480px) {
	#root::before {
		position: absolute;
		display: block;
		width: 100%;
		height: 65%;
		bottom: 35%;
		background: url('/assets/img/home_bg.svg') center bottom / cover no-repeat;
		content: '';
		z-index: -1;
		opacity: 0.7;
	}

	#root::after {
		position: absolute;
		display: block;
		width: 51px;
		height: 18px;
		bottom: 1em;
		right: 1em;
		background: url('/assets/img/logo.svg') center center / contain no-repeat;
		content: '';
		z-index: -1;
	}

	.Home {
		position: relative;
		display: block;
		overflow: hidden;
		overflow-y: auto;
		height: auto;
		width: 400px;
		background: white;
		border-radius: 10px;
		box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.1);

		.Top {
			padding-top: 1em;
			border-radius: 10px;
			margin-bottom: 2em;

			.CTA {
				padding-top: 1.5em;
				margin-left: 20px;
			}

			.HomeLogo {
				width: 40%;
				margin: 0.75em auto;
			}

			.Character {
				width: auto;

				img {
					max-width: none;
				}
			}
		}
	}
}

@media screen and (min-width: 400px) {
	.Home .Top {
		min-height: 262px;
	}

	.Home .QuizButton {
		margin-top: 2em;
	}
}

@media screen and (max-width: 359px) {
	.Home .Top .CTA {
		margin-left: 4px;
	}
}
