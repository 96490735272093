.Question.current {
	.Casino_outerDial {
		animation: CasinoRotate 4s ease 3s infinite;
		transform-origin: 230px 289px;
	}

	.Casino_cross {
		animation: CasinoRotateReverse 4s ease 3s infinite;
		transform-origin: 230px 289px;
	}
}

@keyframes CasinoRotate {
	0% {
		transform: rotate(0deg);
	}
	50%,
	100% {
		transform: rotate(360deg);
	}
}

@keyframes CasinoRotateReverse {
	0% {
		transform: rotate(0deg);
	}
	50%,
	100% {
		transform: rotate(-360deg);
	}
}
