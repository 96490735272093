* {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	user-select: none;
	-webkit-overflow-scrolling: touch;
	font-family: proxima-nova, sans-serif;
	font-weight: 400;
	font-style: normal;
}

#root {
	width: 100%;
	min-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
