.Quiz {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: fixed;
	animation: fade 1s;

	@keyframes fade {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}

.NotFixedQuiz {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: fade 1s;
	overflow-x: hidden;

	@keyframes fade {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}

@media screen and (min-width: 480px) {
	.Quiz,
	.NotFixedQuiz {
		max-height: 600px;
		width: 400px;
		box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		background: white;
	}

	.NotFixedQuiz {
		position: relative;
	}

	.Check {
		position: static;
	}

	.NoShadow {
		box-shadow: none;
	}
}
