.Question.current {
	.Env_cloud {
		animation: EnvLR 8s ease-in-out 3s infinite;
	}

	.Env_birdL,
	.Env_birdR {
		animation: EnvBirdR 8s ease-in-out 3s infinite;
		transform-origin: 450px 130px;
	}

	.Env_birdL {
		animation-name: EnvBirdL;
		animation-delay: 3.5s;
		transform-origin: 360px 101px;
	}
}

@keyframes EnvLR {
	0% {
		transform: translateX(0);
	}
	15% {
		transform: translateX(-45px);
	}
	45% {
		transform: translateX(45px);
	}
	60%,
	100% {
		transform: translateX(0);
	}
}

@keyframes EnvBirdR {
	0% {
		transform: translateY(0);
	}
	15% {
		transform: translateY(-10px) rotate(-20deg);
	}
	45% {
		transform: translateY(10px);
	}
	60%,
	100% {
		transform: translateY(0);
	}
}

@keyframes EnvBirdL {
	0% {
		transform: translateY(0);
	}
	15% {
		transform: translateY(-10px) rotate(20deg);
	}
	45% {
		transform: translateY(10px);
	}
	60%,
	100% {
		transform: translateY(0);
	}
}
