.Question.current {
	.Building_mult {
		fill: #a2a2a2;
		animation: BuildingMult 4s linear 3s infinite;

		&.delay1 {
			animation-delay: 0.5s;
		}

		&.delay2 {
			animation-delay: 1s;
		}
	}
}

@keyframes BuildingMult {
	0% {
		opacity: 1;
	}

	13% {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	40%,
	100% {
		opacity: 1;
	}
}
