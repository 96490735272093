@import '../../styles/colors';

$roomGrey: rgba(0, 0, 0, 0.5);
$borderBottomGrey: rgba(0, 0, 0, 0.3);
$dashedBorderBottomGrey: rgba(0, 0, 0, 0.1);

.Events {
	width: 100%;
	background-color: #fff;
	height: auto;
	animation: fade 1s;

	@keyframes fade {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	.Title {
		height: 190px;
		width: 100%;
		background: url('../../assets/img/agenda_bg.svg') no-repeat top center;
		background-size: cover;

		.Logo {
			width: 45px;
			margin: 2em;
			margin-bottom: 0;
		}

		.TitleContent {
			display: flex;
			align-items: center;
			margin-left: 2em;
			padding-top: 2em;

			.TitleInfo {
				.topLogo {
					width: 34px;
					margin-left: 15px;
				}
				h2 {
					color: #fff;
					margin-left: 15px;
					margin-top: 6px;
					margin-bottom: 6px;
				}
				.Duration {
					margin-left: 15px;
					color: $orange;
					font-weight: 300;
				}
			}
		}
	}

	.Back {
		display: block;
		width: 25px;
		height: 25px;
		background-color: #fff;
		border-radius: 50%;

		img {
			height: 100%;
		}

		&.lonely {
			margin-top: 4em;
			margin-left: 2em;
			width: auto;
			line-height: 25px;
			color: $blue !important;
			text-decoration: none !important;

			img {
				vertical-align: middle;
				margin-right: 8px;
			}
		}
	}

	.EventsList {
		width: 100%;
		padding: 0 2em;
		margin-top: 4em;

		.DayBlock {
			width: 100%;
			font-size: 14px;
			border-bottom: 1px solid $borderBottomGrey;
			padding-bottom: 1.7em;
			margin-bottom: 1.7em;

			.Day {
				color: $orange;
				font-size: 20px;
				font-weight: 300;
				display: block;
				margin-bottom: 10px;
			}

			.TimeRoomBlock {
				display: flex;
				margin: 1.2em 0;

				.Time {
					font-weight: 800;
					margin-right: 20px;

					i {
						margin-right: 5px;
					}
				}

				.Room {
					color: $roomGrey;
					font-weight: 300;

					i {
						margin-right: 5px;
					}
				}
			}

			.EventTitle {
				font-weight: bold;
				margin-bottom: 10px;
				font-size: 15px;
			}

			.Description {
				line-height: 20px;
				color: #000;
				padding-bottom: 1.2em;
				border-bottom: 1px dashed $dashedBorderBottomGrey;
				font-weight: 300;
				font-size: 15px;
				letter-spacing: 1px;

				.Speakers {
					color: $roomGrey;
					font-weight: 300;
					margin-top: 10px;
					font-size: 14px;
				}
			}
		}
	}
}

@media screen and (min-width: 480px) {
	.Events {
		max-height: 90vh;
		width: 400px;
		border-radius: 10px;
		box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.1);
		margin: 5vh auto;
		overflow: hidden;
		overflow-y: scroll;

		.Top {
			border-radius: 10px;
		}
	}
}
