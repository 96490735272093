.Instructions {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	-webkit-animation: fadeInOut 5s ease-in-out forwards;
	animation: fadeInOut 5s ease-in-out forwards;
	opacity: 0;

	@keyframes fadeInOut {
		18% {
			opacity: 1;
		}
		92% {
			opacity: 1;
		}
	}

	h1 {
		font-size: 25px;
		color: #fff;
		width: 80%;
		text-align: center;
		margin-bottom: 2em;
	}

	img {
		margin-left: -50px;
		width: 30%;
		animation: swipe 2.5s ease infinite;
	}

	@keyframes swipe {
		50% {
			transform: translateX(50px);
		}
		100% {
			transform: translateX(0%);
		}
	}
}
