.Questions {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translateZ(0);
}

.Container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translateZ(0);
}
