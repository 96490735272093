@import '../../styles/colors';

.PleaseRotate {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $blue;
	padding: 1em;
	text-align: center;
}
